import { DOMRef } from "@react-types/shared";
import { filterDOMProps } from "@react-aria/utils";
import React, { forwardRef } from "react";
import { TextProps } from "../../../interfaces/text";
import { useDOMRef } from "../../../hooks/useDOMRef";

function Text(props: TextProps, ref: DOMRef) {
  let { children, ...otherProps } = props;
  let { className } = otherProps;
  let domRef = useDOMRef(ref);

  return (
    <span {...filterDOMProps(otherProps)} className={className} ref={domRef}>
      {children}
    </span>
  );
}

/**
 * Text represents text with no specific semantic meaning.
 */
const _Text = forwardRef(Text);
export { _Text as Text };
