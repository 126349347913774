import { Submission } from "@prisma/client";
import { SodiumPlus, X25519PublicKey, X25519SecretKey } from "sodium-plus";

let sodium: SodiumPlus;
let secretKey: X25519SecretKey | null = null;

if (process.env.SECRET_KEY) {
  secretKey = X25519SecretKey.from(process.env.SECRET_KEY ?? "", "hex");
}

const publicKey = X25519PublicKey.from(
  process.env.NEXT_PUBLIC_KEY ?? "",
  "hex"
);

export const decryptFormData = async (obj: any) => {
  let decryptedObject: { [key: string]: any } = {};

  for (const id in obj) {
    const decryptedData = decryptObject(obj[id].data);
    decryptedObject[id] = { ...obj[id], data: decryptedData };
  }
};

export const decryptObject = async (obj: any) => {
  let decryptedObject: { [key: string]: any } = {};

  for (const id in obj) {
    const objectData = obj[id].data;
    let decryptedData: { [key: string]: any } = {};

    if (objectData) {
      for (const dataId in objectData) {
        let decryptedString;
        const item = objectData[dataId];
        if (item.value) {
          decryptedString = await decryptString(item.value);
          decryptedData[dataId] = { ...item, value: decryptedString };
        } else if (item.label) {
          decryptedString = await decryptString(item.label);
          decryptedData[dataId] = { ...item, label: decryptedString };
        }
        decryptedObject[id] = {
          ...obj[id],
          data: decryptedData,
        };
      }
    }
  }
  return decryptedObject;
};

export const decryptArray = async (array: Array<any>) => {
  let decrypted = [];
  for (let i = 0; i < array.length; i++) {
    let item = array[i];
    let decryptedString;
    if (item.value) {
      decryptedString = await decryptString(item.value);
      decrypted.push({ ...item, value: decryptedString });
    } else if (item.label) {
      decryptedString = await decryptString(item.label);
      decrypted.push({ ...item, label: decryptedString });
    }
  }
  return decrypted;
};

export const decryptString = async (string: string) => {
  if (secretKey) {
    if (!sodium) sodium = await SodiumPlus.auto();
    const encrypted = Buffer.from(JSON.parse(string));

    let decryptedKeyData = await sodium.crypto_box_seal_open(
      encrypted,
      publicKey,
      secretKey
    );
    return decryptedKeyData.toString("utf-8");
  } else {
    return null;
  }
};

export const decryptSubmission = async (submission: Submission) => {
  const answers: { [key: string]: any } = ({} = JSON.parse(
    JSON.stringify(submission.answers)
  ));

  let mustDecryptObject: { [key: string]: any } = {};
  for (const id in answers) {
    if (answers[id].encrypt) {
      mustDecryptObject[id] = answers[id];
    }
  }

  const decryptedData = await decryptObject(mustDecryptObject);
  const decryptedAnswers = { ...answers, ...decryptedData };
  const decryptedSubmission: Submission = {
    ...submission,
    answers: decryptedAnswers,
  };
  return decryptedSubmission;
};
export const decryptSubmissionAnswers = async (answersData: any) => {
  const answers: { [key: string]: any } = ({} = JSON.parse(
    JSON.stringify(answersData)
  ));

  let mustDecryptObject: { [key: string]: any } = {};
  for (const id in answers) {
    if (answers[id].encrypt) {
      mustDecryptObject[id] = answers[id];
    }
  }

  const decryptedData = await decryptObject(mustDecryptObject);
  const decryptedAnswers = { ...answers, ...decryptedData };
  return decryptedAnswers;
};
