import { decryptString } from "./decrypt";

export function getErrorMessage(error: any) {
  if (error.graphQLErrors) {
    for (const graphQLError of error.graphQLErrors) {
      if (
        graphQLError.extensions &&
        graphQLError.extensions.code === "BAD_USER_INPUT"
      ) {
        return graphQLError.message;
      }
    }
  }
  return error.message;
}

/**
 * User methods. The example doesn't contain a DB, but for real applications you must use a
 * db here, such as MongoDB, Fauna, SQL, etc.
 */

const users = [];

export interface SubmitFormProps {
  id: string;
  answers: any;
  score: number;
}

export interface VerifySubmissionProps {
  id: string;
  uuid: string;
}

export async function submitForm({ id, answers, score }: SubmitFormProps) {
  return fetch(`/api/forms/${id}/submit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id, answers, score }),
  });
}

export async function verifySubmission({ id, uuid }: VerifySubmissionProps) {
  return fetch(`/api/submission/${id}/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ uuid }),
  }).then((res: any) => res.json());
}
export async function confirmSubmission(id: string) {
  return fetch(`/api/submission/${id}/confirm`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res: any) => res.json());
}

export const decryptNameAndMail = async (answers: any) => {
  let email = { id: "", value: "", label: "" };
  let name = { id: "", value: "", label: "" };
  const answerArray: any[] = Object.values(JSON.parse(JSON.stringify(answers)));
  answerArray.forEach((el) => {
    if (el.data) {
      Object.values(el.data).forEach((data: any) => {
        if (
          data.label.toLowerCase().includes("email") ||
          data.label.toLowerCase().includes("e-mail")
        ) {
          email = data;
        } else if (data.label.toLowerCase().includes("vorname")) {
          name = data;
        }
      });
    }
  });
  const decryptedEmail = await decryptString(email.value);
  const decryptedName = await decryptString(name.value);
  return { name: decryptedName, email: decryptedEmail };
};
