import { DOMRef } from "@react-types/shared";
import { filterDOMProps, mergeProps } from "@react-aria/utils";
import React, { ElementType, forwardRef } from "react";
import { HeadingProps } from "../../../interfaces/heading";
import { useDOMRef } from "../../../hooks/useDOMRef";

const slotDOMProps = new Set(["aria-current"]);

function Heading(props: HeadingProps, ref: DOMRef<HTMLHeadingElement>) {
  let domProps = filterDOMProps(props);

  let { children, level = 3, ...otherProps } = props;
  let { className } = otherProps;
  let domRef = useDOMRef(ref);
  let HeadingTag = `h${level}` as ElementType;

  return (
    <HeadingTag {...domProps} className={`h${level} ${className}`} ref={domRef}>
      {children}
    </HeadingTag>
  );
}

/**
 * Heading is used to create various levels of typographic hierarchies.
 */
const _Heading = forwardRef(Heading);
export { _Heading as Heading };
