import { FocusableRef } from "@react-types/shared";
import React from "react";
import { useButton } from "@react-aria/button";
import { useFocusableRef } from "../../../hooks/useDOMRef";
import { ButtonProps } from "../../../interfaces/button";
import { Text } from "../Text/Text";
import Spinner from "../Spinner/Spinner";

function _Button(props: ButtonProps, ref: FocusableRef) {
  let {
    elementType: ElementType = "button",
    children,
    variant,
    size,
    isQuiet,
    isDisabled,
    isLoading,
    className,
    showTextWhileLoading,
    hasSucceeded,
    onPress,
    ...otherProps
  } = props;
  let buttonProps: { [key: string]: any } = { ...otherProps };

  let buttonClassNames: string = "";
  if (hasSucceeded) {
    buttonProps.disabled = true;
    buttonClassNames = "success";
  } else if (isDisabled) {
    buttonClassNames = "disabled";
  }

  let content;
  if (isLoading) {
    if (showTextWhileLoading) {
      content = (
        <div className="flex">
          <div className="mr-2">
            <Spinner size={size} />
          </div>
          <Text>{children}</Text>
        </div>
      );
    } else {
      content = (
        <div className="flex justify-center">
          <Spinner size={size} />
        </div>
      );
    }
  } else {
    content = typeof children === "string" ? <Text>{children}</Text> : children;
  }

  return (
    <ElementType
      className={`btn ${size} btn-${variant} ${buttonClassNames} ${className}`}
      onClick={onPress}
      {...buttonProps}
    >
      {content}
    </ElementType>
  );
}

/**
 * Buttons allow users to perform an action or to navigate to another page.
 * They have multiple styles for various needs, and are ideal for calling attention to
 * where a user needs to do something in order to move forward in a flow.
 */
let Button = React.forwardRef(_Button);
Button.defaultProps = {
  size: "",
};
export default Button;
